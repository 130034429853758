/* src/components/CodeEditor.css */
.code-editor h2 {
    margin-bottom: 10px;
    color: #ffd700;
}

.comment {
    background-color: #f8f9fa;
    padding: 10px;
    border-radius: 5px;
    margin-top: 10px;
}