/* src/assets/Comment.css */
/* src/components/TaskDescription.css */
.comment h2 {
    margin-bottom: 10px;
    color: #ffd700;
}

.comment p {
    color: #e0e0e0;
    line-height: 1.6;
}

.comment {
    padding: 20px;
    background-color: #333;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
    overflow-y: auto;
}