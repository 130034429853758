/* src/App.css */
.app-container {
    display: flex;
    height: 100vh;
    background-color: #2e2e2e;
    color: #e0e0e0;
    font-family: 'Arial', sans-serif;
}

.task-section {
    flex: 1;
    padding: 20px;
    background-color: #333;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
    overflow-y: auto;
}

.editor-section {
    flex: 2;
    padding: 20px;
    display: flex;
    flex-direction: column;
    background-color: #1e1e1e;
}

.submit-button {
    padding: 10px;
    font-size: 18px;
    color: #ffffff;
    background-color: #007bff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    align-self: flex-start;
    margin-top: 20px;
}

.submit-button:hover {
    background-color: #0056b3;
}