/* src/assets/History.css */
.history h2 {
    margin-bottom: 10px;
    color: #ffd700;
}

.history ul {
    list-style-type: disc;
    padding-left: 20px;
    color: #e0e0e0;
}

.history li {
    margin-bottom: 5px;
}