/* src/components/TaskDescription.css */
.task-description h2 {
    margin-bottom: 10px;
    color: #ffd700;
}

.task-description p {
    color: #e0e0e0;
    line-height: 1.6;
}

.task-description pre code {
    white-space: pre-wrap;
}