/* src/assets/SuccessMessage.css */
.success-message {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 255, 0, 0.8);
    color: white;
    font-size: 2em;
    padding: 20px;
    border-radius: 10px;
    z-index: 1000;
    animation: fadeInOut 2s ease-in-out forwards;
    text-align: center;
}

@keyframes fadeInOut {
    0% {
        opacity: 0;
        transform: translate(-50%, -60%);
    }

    10% {
        opacity: 1;
        transform: translate(-50%, -50%);
    }

    90% {
        opacity: 1;
        transform: translate(-50%, -50%);
    }

    100% {
        opacity: 0;
        transform: translate(-50%, -40%);
    }
}